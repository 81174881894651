<template>
    <div
        v-loading.full.screen.lock="m__loading"
        class="w-screen h-screen util-flex-center flex-col"
    >
        <div class="flex items-center space-x-3 mb-6">
            <img
                class="w-[60px] h-[60px]"
                :src="require('@/assets/images/logo_brand.png')"
            />

            <p
                class="uppercase font-semibold text-4xl text-brand-atosa spacing-1"
            >
                ATAME
            </p>
        </div>

        <img
            v-if="status_verify === 1"
            :src="require('@/assets/images/success.gif')"
            class="h-[240px]"
            alt=""
        />
        <img
            v-if="status_verify === 0"
            :src="require('@/assets/images/error.gif')"
            class="h-[240px]"
            alt=""
        />

        <p
            v-if="status_verify === 1"
            class="text-2xl font-semibold mt-4 text-success"
        >
            {{ message_auth_code }}
        </p>

        <p
            v-if="status_verify === 0"
            class="text-2xl font-semibold mt-4 text-red"
        >
            {{ message_auth_code }}
        </p>

        <p class="text-desc-text mt-2">
            {{ description_message }}
        </p>

        <el-button
            icon="el-icon-back"
            type="primary"
            class="mt-10"
            @click="handleChangeLocation"
        >
            {{ $t('button.back_dashboard') }}
        </el-button>
    </div>
</template>

<script>
import axios from 'axios'
import i18n from '@/plugins/language'

export default {
    data() {
        return {
            message_auth_code: '',
            description_message: '',
            status_verify: -1,
            domain: 'https://atame.atosa.asia'
        }
    },

    async mounted() {
        this.m__loading = true

        try {
            const { code, state } = this.$route.query
            if (code && state !== 'your_custom_params') {
                const arr = state.split('-----')
                if (arr.length && arr.length === 5) {
                    if (arr[3] === 'io') {
                        this.domain = 'https://atame.atosa.io'
                    }

                    if (arr[4] === 'en') {
                        i18n.locale = 'en'
                        localStorage.setItem('code_lang', 'en')
                    } else {
                        i18n.locale = 'vi'
                        localStorage.setItem('code_lang', 'vi')
                    }

                    const response_create_tiktok_seller = await axios.get(
                        `${process.env.VUE_APP_ATOSA_TIKTOK_ADS_API}/tiktok_seller/auth/${arr[2]}`,
                        {
                            params: {
                                auth_code: code,
                                shop_code: arr[1]
                            },
                            headers: {
                                authorization: arr[0]
                            }
                        }
                    )

                    if (response_create_tiktok_seller.data.code !== 0) {
                        throw new Error(
                            response_create_tiktok_seller.data.message
                        )
                    }

                    this.p__showNotify(
                        'success',
                        this.$t('page.shop.connect_tiktok_shop_success')
                    )

                    this.status_verify = 1

                    this.message_auth_code = this.$t(
                        'page.shop.verify_tiktok_shop_success'
                    )
                    this.description_message = this.$t(
                        'page.shop.message_verify_success'
                    )
                }
            }
        } catch (error) {
            this.status_verify = 0
            this.message_auth_code = this.$t(
                'page.shop.verify_tiktok_shop_fail'
            )
            this.description_message = this.$t('page.shop.please_verify_again')
            console.error(error)
        }

        this.m__loading = false
    },

    methods: {
        handleChangeLocation() {
            window.location = `${this.domain}${this.p__group_route.ads_manager}/${this.p__route.tiktok_shop}`
        }
    }
}
</script>
